import { Model, type ModelConstructor } from "@moirei/dobby";
import type { FetchPolicy } from "@apollo/client";
import type { ComponentInstance, PropType } from "vue";
import { VBtn, VSelect } from "vuetify/components";
import { get, isString, omit } from "lodash";

type Ctx<T> = {
  result: T[];
  updating: boolean;
  select: (item: T) => void;
  readonly fetching: boolean;
};

type Instance<T> = ComponentInstance<any> & Ctx<T>;

type BaseOptions<T> = {
  name: string;
  icon: string;
  placeholder: string;
  itemTitle?: string;
  itemValue?: string;
  action?: {
    title: string;
    handler: { (this: Ctx<T>): any };
  };
};

const baseProps = <T>(o: BaseOptions<T>) => ({
  placeholder: { type: String, default: o.placeholder },
  itemTitle: { type: String, default: o.itemTitle || "name" },
  itemValue: { type: String, default: o.itemValue || "id" },
  mandatory: { type: Boolean, default: false },
  returnObject: { type: Boolean, default: false },
});

const renderSelect = <T>(o: {
  instance: Instance<T>;
  omitProps: string[];
  icon?: string;
  action?: BaseOptions<T>["action"];
}) => {
  return h(
    VSelect,
    {
      ...omit(o.instance.$props, o.omitProps),
      items: o.instance.result,
      modelValue: o.instance.modelValue,
      "onUpdate:modelValue": (v: any) =>
        o.instance.$emit("update:modelValue", v),
      prependInnerIcon: o.icon,
    },
    {
      "append-inner": () => {
        if (o.instance.fetching || o.instance.updating) {
          return h(VBtn, {
            loading: true,
            icon: true,
            size: 20,
            variant: "text",
          });
        } else {
          if (o.action) {
            return h(VBtn, {
              class: "text-none",
              text: o.action.title,
              elevation: 1,
              onClick: withModifiers(o.action.handler.bind(o.instance as any), [
                "prevent",
              ]),
            });
          }
        }
      },
    }
  );
};

export const defineModelSelectComponent = <T extends Model>(
  o: BaseOptions<T> & {
    model: ModelConstructor<T>;
  }
) => {
  return defineComponent({
    name: o.name,
    props: {
      ...VSelect.props,
      ...baseProps(o),
      additionalSelect: {
        type: [Array, String] as PropType<string | string[]>,
      },
      channel: { type: String },
      cachePolicy: {
        type: String as PropType<FetchPolicy>,
        default: "cache-first",
      },
    },
    setup(props, ctx) {
      const updating = ref(false);
      const query = o.model.select(props.itemTitle, props.itemValue);
      if (props.additionalSelect) {
        query.add(props.additionalSelect);
      }
      if (props.channel) {
        query.where("publishedTo", props.channel);
      }
      if (props.cachePolicy) {
        query.policy(props.cachePolicy);
      }

      const { loading: fetching, items: result } = useListQuery(query);

      const select = (item: unknown) => {
        ctx.emit(
          "update:modelValue",
          !props.returnObject && !isString(item)
            ? get(item, props.itemValue)
            : item
        );
      };

      const w_ = watch(result, (items) => {
        const unsub = once(w_);
        if (items.length && props.mandatory && !props.modelValue) {
          select(items[0]);
          unsub();
        }
      });

      return {
        updating,
        result,
        fetching,
        select,
      };
    },
    render() {
      return renderSelect({
        instance: this,
        action: o.action,
        omitProps: ["mandatory", "additionalSelect", "channel", "cachePolicy"],
      });
    },
  });
};

export const defineQuerySelectComponent = <T extends Model>(
  o: BaseOptions<T> & {
    fetch: () => Promise<T[]>;
  }
) => {
  return defineComponent({
    name: o.name,
    props: {
      ...VSelect.props,
      ...baseProps(o),
    },
    setup(props, ctx) {
      const updating = ref(false);
      const fetching = ref(false);
      const result = shallowRef<T[]>([]);

      const select = (item: unknown) => {
        ctx.emit(
          "update:modelValue",
          !props.returnObject && !isString(item)
            ? get(item, props.itemValue)
            : item
        );
      };

      const fetch = async () => {
        fetching.value = true;
        const items = await o.fetch();
        fetching.value = false;
        result.value = items;
        if (items.length && props.mandatory && !props.modelValue) {
          select(items[0]);
        }
      };

      onMounted(fetch);

      return {
        updating,
        result,
        fetching,
        select,
        fetch,
      };
    },
    render() {
      return renderSelect({
        instance: this,
        action: o.action,
        icon: o.icon,
        omitProps: ["mandatory", "additionalSelect"],
      });
    },
  });
};
